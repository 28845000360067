<template>
  <div>
    <b-card>
      <div>
        <div class="d-flex align-items-center justify-center pb-1">
          <feather-icon icon="DownloadCloudIcon" size="16" />
          <h4 class="pl-1" style="padding-top: 8px;">Ftp</h4>
        </div>
        <validation-observer ref="ftpValidation">
          <b-row>
            <b-col cols="6"
              ><vi-input
                rules="required"
                v-model="ftp.username"
                :label="$t('username')"
              ></vi-input
            ></b-col>
            <b-col cols="6"
              ><vi-input
                rules="required"
                v-model="ftp.password"
                :label="$t('password')"
              ></vi-input
            ></b-col>
          </b-row>
          <b-row>
            <b-col cols="6"
              ><vi-input
                rules="required"
                v-model="ftp.mac"
                :label="$t('mac')"
              ></vi-input
            ></b-col>
            <b-col cols="6"></b-col>
          </b-row>
        </validation-observer>
        <b-button @click="confirm" variant="primary">{{ $t('save') }}</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BCol,
  BRow,
  BButton,
} from 'bootstrap-vue';
import ViInput from '@/views/components/ViInput.vue';
import ViSelect from '../../components/ViSelect.vue';
import { ValidationObserver } from 'vee-validate';
import { onMounted, ref } from '@vue/composition-api';
import store from '@/store';
import { dangerToast, successToast } from '@/utils/toast';
import i18n from '@/libs/i18n';
import { useToast } from 'vue-toastification/composition';

export default {
  components: {
    BCard,
    BFormInput,
    BFormGroup,
    ViInput,
    BRow,
    BCol,
    BButton,
    Permissions,
    ViSelect,
    ValidationObserver,
  },
  setup(props, context) {
    const toastInstance = useToast();

    const ftpId = context.root.$route.params.id;

    const ftpValidation = ref(null);

    const ftp = ref({
      mac: null,
      username: null,
      password: null,
    });

    onMounted(async () => {
      if (ftpId) {
        const response = await store.dispatch('ftp/GET_FTP_DETAIL', ftpId);
        ftp.value = response.data;
      }
    });

    const confirm = async () => {
      const validation = await ftpValidation.value.validate();
      if (ftpId || validation) {
        let response = null;
        try {
          response = await store.dispatch(
            ftpId ? 'ftp/UPDATE_FTP' : 'ftp/CREATE_FTP',
            {
              ftpId,
              ftp: ftp.value,
            },
          );
          if (response) {
            successToast(
              toastInstance,
              i18n.t('success'),
              ftpId ? i18n.t('ftp_updated') : i18n.t('ftp_created'),
            );
            context.root.$router.push({ name: 'ftp-credentials' });
          } else {
            dangerToast(
              toastInstance,
              i18n.t('failed'),
              ftpId ? i18n.t('profile.failed') : i18n.t('user_creation_failed'),
            );
          }
        } catch (e) {
          console.log(e);
          dangerToast(
            toastInstance,
            i18n.t('failed'),
            i18n.t(e.response.data.message),
          );
        }
      }
    };

    return { ftp, ftpId, confirm, ftpValidation };
  },
};
</script>

<style></style>
